import React from "react";
import "./App.css";
import {GoogleMap} from "./components/GoogleMap";

function App() {
    return (
        <div id="app">
            <GoogleMap/>
        </div>
    );
}

export default App;
