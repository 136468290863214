import {InfoWindow, Marker} from "@react-google-maps/api";
import PropTypes from "prop-types";
import React, {useState} from "react";

export const PointOfInterest = ({
                                    AddressInfo: {
                                        Title,
                                        AddressLine1,
                                        Latitude,
                                        Longitude,
                                        Postcode
                                    }
                                }) => {
    const position = {
        lat: Latitude,
        lng: Longitude
    };
    const [clicked, setClicked] = useState(false);

    return <Marker
        position={position}
        onClick={() => setClicked(!clicked)}
    >
        {
            clicked && (
                <InfoWindow
                    position={position}
                >
                    <p>{Title} @ {AddressLine1} {Postcode}</p>
                </InfoWindow>
            )
        }
    </Marker>;
};

PointOfInterest.propTypes = {
    AddressInfo: PropTypes.object
};

export default PointOfInterest;
