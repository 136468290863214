import queryString from "query-string";

export const getPointsOfInterest = ({
                                        output = process.env.REACT_APP_OPEN_CHARGE_API_OUTPUT,
                                        countrycode = process.env.REACT_APP_OPEN_CHARGE_API_COUNTRY,
                                        maxresults = process.env.REACT_APP_OPEN_CHARGE_API_PAGE_SIZE,
                                        latitude = process.env.REACT_APP_DEFAULT_LAT,
                                        longitude = process.env.REACT_APP_DEFAULT_LONG
                                    } = {}) => {
    const query = queryString.stringify({
        output,
        countrycode,
        maxresults,
        latitude,
        longitude
    });
    const uri = `${process.env.REACT_APP_OPEN_CHARGE_API_BASE_URL}/poi?${query}`;

    return fetch(uri, {
        method: "GET",
        headers: {
            accept: "application/json",
            "content-type": "application/json"
        }
    })
        .then(async (response) => {
            if (!response) {
                throw new Error("api:error");
            }

            const body = await response.json();

            if (!response.ok) {
                throw new Error("api:error");
            }

            return body;
        });
};
