import {GoogleMap as GoogleMapActual, OverlayView, useLoadScript} from "@react-google-maps/api";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {getPointsOfInterest} from "../api/getPointsOfInterest";
import {PointOfInterest} from "./PointOfInterest";
import {Spinner} from "./Spinner";

export const GoogleMap = ({
    defaultCenter = {
        lat: Number(process.env.REACT_APP_DEFAULT_LAT),
        lng: Number(process.env.REACT_APP_DEFAULT_LONG)
    },
    mapContainerStyle = {
        height: "100vh",
        width: "100vw"
    },
    options = {},
    zoom = 13,
    ...props
}) => {
    const [errorState, setErrorState] = useState(null);
    const [poiState, setPoiState] = useState([]);
    const {isLoaded, loadError: mapsLoadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    useEffect(() => {
        getPointsOfInterest()
            .then(setPoiState)
            .catch(setErrorState);
    }, []);

    if (mapsLoadError) {
        setErrorState(mapsLoadError);
    }

    if (errorState) {
        return <div className="error">
            <div>
                <h1>¯\_ʕ•ᴥ•ʔ_/¯</h1>
                <p>Well, I tried.</p>
                <p>Normally I'd tell you that I'd been alerted and am looking into things, but I'm not plugging Sentry
                    into a 2 hour coding challenge.</p>
            </div>
        </div>;
    }

    return isLoaded
        ? <GoogleMapActual
            id="map"
            center={defaultCenter}
            mapContainerClassName="map"
            mapContainerStyle={mapContainerStyle}
            options={options}
            zoom={zoom}
            {...props}
        >
            {
                poiState?.length > 0
                    ? poiState.map((pointOfInterest) => {
                        return <PointOfInterest
                            {...pointOfInterest}
                            key={pointOfInterest.ID}
                        />;
                    })
                    : <OverlayView
                        position={defaultCenter}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <Spinner/>
                    </OverlayView>
            }
        </GoogleMapActual>
        : <Spinner/>;
};

GoogleMap.propTypes = {
    defaultCenter: PropTypes.object,
    options: PropTypes.object
};

export default GoogleMap;
